import { FC, ReactElement, useState } from "react";
import { Link } from "react-router-dom";

import { StaticLink } from "../interfaces/StaticLink";
import { staticLinks } from "../data/staticLinks";

export const MobileNavigation: FC = (): ReactElement => {
  const [showNavigation, setShowNavigation] = useState<boolean>(false);

  const onToggleNav = (): void => {
    setShowNavigation((status: boolean): boolean => {
      if (status) {
        document.body.style.overflow = "auto";
      } else {
        document.body.style.overflow = "hidden";
      }

      return !status;
    });
  };

  return (
    <div className="sm:hidden">
      <button type="button" className="ml-1 mr-1 h-8 w-8 rounded py-1" aria-label="Toggle menu" onClick={onToggleNav}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="text-gray-900 dark:text-gray-100"
        >
          {showNavigation ? (
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          ) : (
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          )}
        </svg>
      </button>
      <div
        className={`fixed top-24 right-0 z-10 h-full w-full transform bg-gray-200 opacity-95 duration-300 ease-in-out dark:bg-gray-800 ${
          showNavigation ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button
          type="button"
          aria-label="Toggle menu"
          className="fixed h-full w-full cursor-auto focus:outline-none text-x"
          onClick={onToggleNav}
        />
        <nav className="fixed mt-8 h-full" hidden={!showNavigation}>
          {staticLinks
            .filter((link: StaticLink) => link.showInNavigation === true)
            .map(
              (link: StaticLink, index: number): ReactElement => (
                <div key={index} className="px-12 py-4">
                  <Link
                    to={link.href}
                    className="text-2xl font-bold tracking-widest text-gray-900 dark:text-gray-100"
                    onClick={onToggleNav}
                  >
                    {link.title}
                  </Link>
                </div>
              )
            )}
        </nav>
      </div>
    </div>
  );
};
